.course-card-container {
  width: 280px;
  height: 330px;
  margin: 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 80px;
  text-align: center;
  background-color: rgb(235, 114, 97);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
}

.course-card-container:hover,
.course-image:hover {
  cursor: pointer;
  transform: scale(1.01, 1.01);
}

.course-image {
  width: 280px;
  height: 280px;
  border-top-right-radius: 80px;
  border-top-left-radius: 20px;
}

.course-name {
  margin: 0px;
  padding: 0px;
  color: white;
  font-size: 18px;
}

@media only screen and (max-width: 1100px) {
  .course-card-container {
    width: 260px;
    height: 300px;
    margin: 10px;
    border-top-left-radius: 17px;
    border-top-right-radius: 75px;
  }

  .course-image {
    width: 260px;
    height: 260px;
    border-top-right-radius: 75px;
    border-top-left-radius: 17px;
  }

  .course-name {
    font-size: 16px;
  }
}

@media only screen and (max-width: 970px) {
  .course-card-container {
    width: 230px;
    height: 265px;
    margin: 8px;
    border-top-left-radius: 15px;
    border-top-right-radius: 65px;
  }

  .course-image {
    width: 230px;
    height: 230px;
    border-top-right-radius: 65px;
    border-top-left-radius: 15px;
  }

  .course-name {
    font-size: 14px;
  }
}

@media only screen and (max-width: 860px) {
  .course-card-container {
    width: 200px;
    height: 230px;
    margin: 8px;
    border-top-left-radius: 13px;
    border-top-right-radius: 60px;
  }

  .course-image {
    width: 200px;
    height: 200px;
    border-top-right-radius: 60px;
    border-top-left-radius: 13px;
  }

  .course-name {
    font-size: 12px;
  }
}

@media only screen and (max-width: 760px) {
  .course-card-container {
    width: 260px;
    height: 300px;
    margin: 10px;
    border-top-left-radius: 17px;
    border-top-right-radius: 75px;
  }

  .course-image {
    width: 260px;
    height: 260px;
    border-top-right-radius: 75px;
    border-top-left-radius: 17px;
  }

  .course-name {
    font-size: 16px;
  }
}

@media only screen and (max-width: 660px) {
  .course-card-container {
    width: 230px;
    height: 265px;
    margin: 8px;
    border-top-left-radius: 15px;
    border-top-right-radius: 65px;
  }

  .course-image {
    width: 230px;
    height: 230px;
    border-top-right-radius: 65px;
    border-top-left-radius: 15px;
  }

  .course-name {
    font-size: 14px;
  }
}

@media only screen and (max-width: 580px) {
  .course-card-container {
    width: 200px;
    height: 230px;
    margin: 8px;
    border-top-left-radius: 13px;
    border-top-right-radius: 60px;
  }

  .course-image {
    width: 200px;
    height: 200px;
    border-top-right-radius: 60px;
    border-top-left-radius: 13px;
  }

  .course-name {
    font-size: 12px;
  }
}

@media only screen and (max-width: 520px) {
  .course-card-container {
    width: 260px;
    height: 300px;
    margin: 10px;
    border-top-left-radius: 17px;
    border-top-right-radius: 75px;
  }

  .course-image {
    width: 260px;
    height: 260px;
    border-top-right-radius: 75px;
    border-top-left-radius: 17px;
  }

  .course-name {
    font-size: 16px;
  }
}

@media only screen and (max-width: 400px) {
  .course-card-container {
    width: 230px;
    height: 265px;
    margin: 8px;
    border-top-left-radius: 15px;
    border-top-right-radius: 65px;
  }

  .course-image {
    width: 230px;
    height: 230px;
    border-top-right-radius: 65px;
    border-top-left-radius: 15px;
  }

  .course-name {
    font-size: 14px;
  }
}

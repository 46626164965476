.instructor-cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-vector {
  height: 50px;
  margin: 50px;
}

.arrow-vector:hover {
  cursor: pointer;
}

.arrow-vector-limit {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .arrow-vector {
    height: 40px;
    margin: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .arrow-vector {
    height: 40px;
    margin: 25px;
  }
}

@media only screen and (max-width: 700px) {
  .arrow-vector {
    height: 35px;
    margin: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .arrow-vector {
    height: 45px;
    margin: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .arrow-vector {
    height: 40px;
    margin: 20px;
  }
}

@media only screen and (max-width: 450px) {
  .arrow-vector {
    height: 30px;
    margin: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .arrow-vector {
    height: 27px;
    margin: 12px;
  }
}

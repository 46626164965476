.profile-card-container {
  width: 280px;
  height: 350px;
  border-top-left-radius: 20px;
  border-top-right-radius: 80px;
  background-color: rgba(242, 241, 239, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 20px;
}

.profile-card-container:hover {
  transform: scale(1.03, 1.03);
}

.profile-image {
  width: 230px;
  height: 230px;
  border-radius: 20px;
  border-top-right-radius: 80px;
}

.profile-details {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.profile-name {
  font-size: 24px;
  margin: 0px;
  color: rgb(235, 114, 97);
}

.profile-speciality {
  font-family: "Poppins-light";
  font-size: 15px;
  font-weight: 500;
  margin: 0px 0;
}

@media only screen and (max-width: 1100px) {
  .profile-card-container {
    width: 280px;
    height: 320px;
    border-top-left-radius: 15px;
    border-top-right-radius: 65px;
    margin: 10px;
  }

  .profile-image {
    width: 220px;
    height: 220px;
    border-radius: 15px;
    border-top-right-radius: 65px;
  }

  .profile-details {
    height: 70px;
  }

  .profile-name {
    font-size: 22px;
  }

  .profile-speciality {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1000px) {
  .profile-card-container {
    width: 260px;
    height: 300px;
    border-top-left-radius: 15px;
    border-top-right-radius: 60px;
    margin: 10px;
  }

  .profile-image {
    width: 200px;
    height: 200px;
    border-radius: 15px;
    border-top-right-radius: 60px;
  }

  .profile-details {
    height: 70px;
  }

  .profile-name {
    font-size: 22px;
  }

  .profile-speciality {
    font-size: 14px;
  }
}

@media only screen and (max-width: 920px) {
  .profile-card-container {
    margin: 7px;
  }

  .profile-image {
    width: 200px;
    height: 200px;
    border-radius: 15px;
    border-top-right-radius: 60px;
  }

  .profile-details {
    height: 70px;
  }

  .profile-name {
    font-size: 20px;
  }

  .profile-speciality {
    font-size: 13px;
  }
}

@media only screen and (max-width: 690px) {
  .profile-card-container {
    width: 250px;
    height: 280px;
    border-top-left-radius: 15px;
    border-top-right-radius: 50px;
    margin: 8px;
  }

  .profile-image {
    width: 180px;
    height: 180px;
    border-radius: 15px;
    border-top-right-radius: 50px;
  }

  .profile-details {
    height: 60px;
  }

  .profile-name {
    font-size: 22px;
  }

  .profile-speciality {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .profile-card-container {
    width: 280px;
    height: 320px;
    border-top-left-radius: 15px;
    border-top-right-radius: 65px;
    margin: 10px;
  }

  .profile-image {
    width: 220px;
    height: 220px;
    border-radius: 15px;
    border-top-right-radius: 65px;
  }

  .profile-details {
    height: 70px;
  }

  .profile-name {
    font-size: 22px;
  }

  .profile-speciality {
    font-size: 14px;
  }
}

@media only screen and (max-width: 500px) {
  .profile-card-container {
    width: 260px;
    height: 300px;
    border-top-left-radius: 15px;
    border-top-right-radius: 60px;
    margin: 10px;
  }

  .profile-image {
    width: 200px;
    height: 200px;
    border-radius: 15px;
    border-top-right-radius: 60px;
  }

  .profile-details {
    height: 60px;
  }

  .profile-name {
    font-size: 22px;
  }

  .profile-speciality {
    font-size: 14px;
  }
}

@media only screen and (max-width: 450px) {
  .profile-card-container {
    width: 220px;
    height: 260px;
    border-top-left-radius: 15px;
    border-top-right-radius: 55px;
    margin: 7px;
  }

  .profile-image {
    width: 180px;
    height: 180px;
    border-radius: 15px;
    border-top-right-radius: 55px;
  }

  .profile-details {
    height: 50px;
  }

  .profile-name {
    font-size: 20px;
  }

  .profile-speciality {
    font-size: 13px;
  }
}

.feedback-section-carousel {
  display: flex;
  align-items: center;
}

.feedback-section-container {
  padding: 20px 0 50px 0;
  margin: 50px 0;
  width: 100%;
  height: 550px;
  background-color: rgba(223, 218, 218, 0.5);
}

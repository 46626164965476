@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@700;800&display=swap");
@font-face {
  font-family: "Poppins";
  src: url("/src/assets/fonts/Poppins-Bold.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins-medium";
  src: url("/src/assets/fonts/Poppins-Medium.ttf");
}

body {
  font-family: "Poppins";
  background-color: white;
}

.section-title {
  margin-top: 50px;
  margin-left: 50px;
  font-size: 34px;
  text-align: left;
}

.sub-section-title {
  font-size: 34px;
  text-align: center;
}

.page-title {
  font-size: 40px;
  text-align: center;
  padding-bottom: 15px;
}

.see-more-button {
  color: rgb(235, 114, 97);
  padding: 10px 30px;
}

.see-more-button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1100px) {
  .see-more-button {
    padding: 10px 27px;
    font-size: 15px;
  }

  .section-title {
    margin-top: 40px;
    margin-left: 50px;
    font-size: 32px;
  }

  .sub-section-title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 1050px) {
  .see-more-button {
    padding: 10px 20px;
    font-size: 15px;
  }

  .section-title {
    margin-top: 40px;
    margin-left: 50px;
    font-size: 30px;
  }

  .sub-section-title {
    font-size: 30px;
  }

  .page-title {
    font-size: 37px;
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 950px) {
  .see-more-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .section-title {
    margin-top: 40px;
    margin-left: 50px;
    font-size: 32px;
  }

  .sub-section-title {
    font-size: 32px;
  }

  .page-title {
    font-size: 33px;
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 900px) {
  .see-more-button {
    text-align: justify;
    margin: 0px 30px 15px 30px;
    padding: 0;
    font-size: 15px;
  }
}

@media only screen and (max-width: 850px) {
  .see-more-button {
    margin: 0px 30px 15px 30px;
    padding: 0;
    font-size: 14px;
  }

  .section-title {
    margin-top: 40px;
    margin-left: 50px;
    font-size: 30px;
  }

  .sub-section-title {
    font-size: 30px;
  }

  .page-title {
    font-size: 30px;
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 650px) {
  .page-title {
    font-size: 28px;
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .see-more-button {
    margin: 0px 20px 15px 20px;
    font-size: 12px;
  }

  .page-title {
    font-size: 25px;
    padding-bottom: 12px;
  }
}

@media only screen and (max-width: 450px) {
  .see-more-button {
    margin: 0px 15px 15px 15px;
    font-size: 12px;
  }

  .section-title {
    margin-top: 30px;
    margin-left: 50px;
    font-size: 26px;
  }

  .sub-section-title {
    font-size: 26px;
  }

  .page-title {
    font-size: 22px;
    padding-bottom: 12px;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
  margin-bottom: 10px;
}

.logo-image {
  width: 80px;
  height: 80px;
  margin: 10px 20px 30px 20px;
}

.logo-image {
  cursor: pointer;
}
.nav-bar-list {
  display: flex;
  z-index: 10;
}
.nav-bar-item {
  list-style: none;
  font-size: 18px;
  color: rgb(235, 114, 97);
  font-weight: 600;
  margin: 0 30px;
}

.nav-bar-item:hover {
  color: rgb(206, 81, 64);
  cursor: pointer;
}

.nav-bar-item-hashlink,
.side-bar-item-hashlink {
  text-decoration: none;
}

@media only screen and (max-width: 1100px) {
  .header-container {
    margin-bottom: 10px;
  }

  .logo-image {
    width: 75px;
    height: 75px;
    margin: 10px 20px 30px 20px;
  }

  .nav-bar-item {
    font-size: 17px;
    margin: 0 25px;
  }
}

@media only screen and (max-width: 1010px) {
  .header-container {
    margin-bottom: 10px;
  }

  .logo-image {
    width: 72px;
    height: 72px;
    margin: 10px 20px 20px 20px;
  }

  .nav-bar-item {
    font-size: 16px;
    font-weight: 600;
    margin: 0 22px;
  }
}

@media only screen and (max-width: 950px) {
  .header-container {
    margin-bottom: 10px;
  }

  .logo-image {
    width: 65px;
    height: 65px;
    margin: 10px 15px 10px 15px;
  }

  .nav-bar-item {
    font-size: 14px;
    font-weight: 600;
    margin: 0 20px;
  }
}

@media only screen and (max-width: 850px) {
  .header-container {
    margin-bottom: 10px;
  }

  .logo-image {
    width: 65px;
    height: 65px;
    margin: 10px 15px 10px 15px;
  }

  .nav-bar-item {
    font-size: 14px;
    font-weight: 600;
    margin: 0 15px;
  }
}

@media only screen and (max-width: 775px) {
  .header-container {
    margin-bottom: 8px;
  }

  .logo-image {
    width: 60px;
    height: 60px;
    margin: 10px 12px 10px 12px;
  }

  .nav-bar-item {
    font-size: 14px;
    font-weight: 600;
    margin: 0 12px;
  }
}

@media only screen and (max-width: 730px) {
  .hamburger-icon {
    margin-right: 30px;
    z-index: 10;
  }

  .logo-image {
    width: 70px;
    height: 70px;
    margin: 10px 12px 10px 30px;
  }

  .header-overlay {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1;
  }

  .nav-bar-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 5px 10px 10px rgba(107, 106, 106, 0.1);
    width: 200px;
    height: 350px;
    right: 0;
    top: 80px;
    padding: 10px 0 0 0;
  }

  .nav-bar-item {
    border: 2px solid rgb(235, 114, 97);
    color: rgb(235, 114, 97);
    height: 20px;
    width: 170px;
    font-size: 16px;
    font-family: "Poppins";
    margin: 5px 0;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-bar-item:hover {
    background-color: rgba(107, 106, 106, 0.1);
  }
}

@media only screen and (max-width: 450px) {
  .logo-image {
    width: 60px;
    height: 60px;
    margin: 10px 12px 10px 25px;
  }
}

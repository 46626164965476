.registration-popup-overlay {
  position: fixed;
  background-color: rgba(241, 237, 237, 0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.registration-popup-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 750px;
  height: 500px;
  background-color: rgb(241, 237, 237);
  border: 5px solid rgb(235, 114, 97);
  border-radius: 8px;
  text-align: center;
  z-index: 11;
}

.registration-text-input {
  margin: 10px !important;
}

.note {
  font-family: "Poppins-light";
  font-size: 12px;
  margin-top: 25px;
}

.buttons-container {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}
.popup-button {
  width: 150px;
  font-size: 14px;
  background-color: rgb(235, 114, 97);
  margin: 0 10px;
  padding: 8px 5px;
}

.popup-button-disabled {
  width: 150px;
  font-size: 14px;
  background-color: rgb(241, 167, 157);
  margin: 0 10px;
  padding: 8px 5px;
}

.popup-button:hover {
  cursor: pointer;
  background-color: rgb(206, 81, 64);
}

@media only screen and (max-width: 1000px) {
  .registration-popup-content {
    width: 700px;
    height: 500px;
  }
}

@media only screen and (max-width: 800px) {
  .registration-popup-content {
    width: 600px;
    height: 500px;
  }
}

@media only screen and (max-width: 680px) {
  .registration-popup-content {
    margin-top: 150px;
    width: 85%;
    height: fit-content;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .registration-text-input {
    margin: 5px 40px !important;
  }

  .popup-button,
  .popup-button-disabled {
    width: 120px;
    font-size: 12px;
    margin: 0 8px;
    padding: 5px 3px;
  }
}

@media only screen and (max-width: 550px) {
  .buttons-container {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    justify-content: center;
    align-items: center;
  }

  .popup-button,
  .popup-button-disabled {
    width: 120px;
    font-size: 12px;
    margin: 5px 0px;
    padding: 5px 3px;
  }

  .note {
    font-size: 11px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 450px) {
  .registration-text-input {
    margin: 5px 25px !important;
  }

  .note {
    margin: 15px 10px 5px 10px;
  }
}

@media only screen and (max-width: 350px) {
  .registration-text-input {
    margin: 5px 15px !important;
  }

  .note {
    margin: 15px 10px 5px 10px;
  }
}

@font-face {
  font-family: "Poppins-light";
  src: url("/src/assets/fonts/Poppins-Light.ttf");
}

.about-us-container {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}

.about-us-title {
  font-size: 34px;
  width: 250px;
  text-align: center;
}
.about-us-text {
  font-family: "Poppins-light";
  text-align: justify;
  padding: 10px 30px;
  width: 800px;
}

@media only screen and (max-width: 1100px) {
  .about-us-title {
    font-size: 32px;
    width: 280px;
  }
  .about-us-text {
    padding: 10px 27px;
    width: 750px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 1050px) {
  .about-us-title {
    font-size: 30px;
    width: 280px;
  }
  .about-us-text {
    padding: 10px 20px;
    width: 710px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 950px) {
  .about-us-title {
    font-size: 28px;
    width: 220px;
  }
  .about-us-text {
    padding: 10px 20px;
    width: 660px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 900px) {
  .about-us-container {
    margin-top: 40px;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .about-us-title {
    margin-left: 50px;
    font-size: 32px;
    width: fit-content;
  }
  .about-us-text {
    text-align: justify;
    margin: 0px 30px 15px 30px;
    padding: 0;
    width: 90%;
    font-size: 15px;
  }
}

@media only screen and (max-width: 850px) {
  .about-us-container {
    margin-top: 30px;
    margin-bottom: 0;
  }
  .about-us-title {
    margin-left: 50px;
    font-size: 30px;
    width: fit-content;
  }
  .about-us-text {
    text-align: justify;
    margin: 0px 30px 15px 30px;
    padding: 0;
    width: 90%;
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .about-us-container {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .about-us-text {
    text-align: justify;
    margin: 0px 20px 15px 20px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 450px) {
  .about-us-container {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .about-us-title {
    margin-left: 50px;
    font-size: 26px;
    width: fit-content;
  }

  .about-us-text {
    text-align: justify;
    margin: 0px 15px 15px 15px;
    font-size: 12px;
  }
}

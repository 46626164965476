.instructors-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.instructor-page-container {
  display: flex;
  flex-direction: column;
}

.course-title {
  text-align: center;
  font-size: 25px;
  margin: 0 0 20px 0;
}

.selected-course-content {
  display: flex;
  justify-content: space-evenly;
}

.right-side-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
}
.selected-course-image {
  width: 400px;
  height: 400px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.2);
}

.left-side-content {
  width: 50%;
}

.course-timing {
  text-align: center;
  margin: 2px;
}

.course-description {
  font-family: "Poppins-light";
  text-align: justify;
}

.topic-list-item {
  font-family: "Poppins-light";
}

@media only screen and (max-width: 1050px) {
  .course-title {
    font-size: 25px;
    margin: 0 0 20px 0;
  }

  .right-side-content {
    width: 28%;
  }
  .selected-course-image {
    width: 350px;
    height: 350px;
  }

  .left-side-content {
    width: 50%;
  }

  .course-description,
  .topic-list-item {
    font-size: 14px;
  }

  .course-timing {
    text-align: center;
    margin: 2px;
  }
}

@media only screen and (max-width: 950px) {
  .course-title {
    font-size: 22px;
    margin: 0 0 10px 0;
  }

  .right-side-content {
    width: 25%;
  }
  .selected-course-image {
    width: 310px;
    height: 310px;
  }

  .left-side-content {
    width: 45%;
  }

  .course-description,
  .topic-list-item {
    font-size: 13px;
  }

  .course-timing {
    margin: 2px;
  }
}

@media only screen and (max-width: 800px) {
  .course-title {
    font-size: 20px;
    margin: 0 0 10px 0;
  }

  .right-side-content {
    width: 90%;
  }
  .selected-course-image {
    width: 270px;
    height: 270px;
  }

  .left-side-content {
    width: 90%;
  }

  .course-description,
  .topic-list-item {
    font-size: 11px;
  }

  .course-timing {
    margin: 2px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 730px) {
  .selected-course-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .course-title {
    font-size: 22px;
    margin: 0 0 8px 0;
  }

  .selected-course-image {
    width: 300px;
    height: 300px;
  }

  .left-side-content {
    width: 90%;
  }

  .course-description,
  .topic-list-item {
    font-size: 13px;
  }

  .course-timing {
    margin: 0px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .course-description,
  .topic-list-item {
    font-size: 12px;
  }

  .selected-course-image {
    width: 260px;
    height: 260px;
  }
}

@media only screen and (max-width: 600px) {
  .course-description,
  .topic-list-item {
    font-size: 11px;
  }

  .course-title {
    font-size: 19px;
    margin: 0 0 8px 0;
  }

  .selected-course-image {
    width: 250px;
    height: 250px;
  }

  .course-timing {
    margin: 0px;
    font-size: 14px;
  }
}

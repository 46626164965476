.instructor-popup-container {
  overflow: hidden;
}

.instructor-popup-overlay {
  position: fixed;
  background-color: rgba(241, 237, 237, 0.8);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}

.instructor-popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 450px;
  background-color: rgb(241, 237, 237);
  border: 5px solid rgb(235, 114, 97);
  border-radius: 8px;
  text-align: center;
  z-index: 11;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instructor-image {
  height: 350px;
  width: 350px;
  margin-right: 30px;
  width: auto;
}

.instructor-name {
  font-size: 32px;
  height: 40px;
}

.instructor-speciality {
  font-size: 20px;
  height: 30px;
}

.instructor-bio {
  text-align: justify;
  font-family: "Poppins-light";
  font-size: 15px;
  height: 180px;
}

.instructor-contact-container {
  display: flex;
  align-items: center;
  height: 30px;
}

.contact-icon {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.instructor-contact-info {
  font-family: "Poppins-light";
  font-size: 15px;
  margin: 0;
}

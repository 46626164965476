.course-cards-large-container {
  display: flex;
  justify-content: center;
}

.course-cards-container {
  width: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.see-more-button-container {
  text-align: center;
  margin-top: 20px;
}

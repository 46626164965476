.feedback-card-container {
  text-align: center;
  width: 600px;
  height: 350px;
  background-color: rgb(223, 218, 218);
  margin: 10px;
  padding: 15px;
}

.rating-stars-container {
  display: flex;
  justify-content: center;
}

.rating-paragraph {
  font-family: "Poppins-light";
  font-size: 11px;
  height: 270px;
}

.star-vector {
  width: 18px;
}

.feedback-author {
  font-size: 15px;
}

@media only screen and (max-width: 1100px) {
  .feedback-card-container {
    width: 550px !important;
    height: 350px;
    background-color: rgb(223, 218, 218);
    margin: 8px;
  }

  .rating-paragraph {
    font-size: 11px;
    margin: 15px;
    height: 270px;
  }

  .star-vector {
    width: 18px;
  }

  .feedback-author {
    font-size: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .rating-paragraph {
    font-size: 10px;
    margin: 7px;
    height: 270px;
  }

  .star-vector {
    width: 18px;
  }

  .feedback-author {
    font-size: 13px;
  }
}

@media only screen and (max-width: 380px) {
  .feedback-card-container {
    width: 550px !important;
    height: 380px;
    background-color: rgb(223, 218, 218);
    margin: 8px;
  }
  .rating-paragraph {
    font-size: 9px;
    margin: 4px;
    height: 300px;
  }

  .star-vector {
    width: 18px;
  }

  .feedback-author {
    font-size: 13px;
  }
}

.hamburger {
  /* padding-top: 400px; */
  width: 1.5rem;
  height: 1rem;
  display: flex;
  justify-content: space-between;

  flex-flow: column nowrap;
  z-index: 20;
}

.burger {
  width: 1.5rem;
  height: 0.1rem;
  padding: 1px 0 1px 0px;
  border-radius: 10px;
  background-color: black;
  transform-origin: 0.5px;
  transition: all 0.3s linear;
}
